import(/* webpackMode: "eager" */ "/opt/onscam/www/app/global.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/onscam/www/components/Layouts/DefaultLayout.tsx");
;
import(/* webpackMode: "eager" */ "/opt/onscam/www/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/opt/onscam/www/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/opt/onscam/www/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/opt/onscam/www/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/opt/onscam/www/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"weight\":\"400\",\"subsets\":[\"latin\"]}],\"variableName\":\"PoppinsFont\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/opt/onscam/www/provider/providers.tsx");
