//Loading ui
import { Spinner } from '@nextui-org/react';
import React from 'react';

export const Loading = () => {
    return (
        <div className='w-screen h-screen flex justify-center items-center'>
            <Spinner label='Loading...' color='primary' size='lg' />
        </div>
    );
};