// app/providers.tsx
'use client';

import { NextUIProvider } from '@nextui-org/react';
import { Provider } from 'react-redux';
import store from '../store/index';
import { ClerkProvider } from '@clerk/nextjs';

export function Providers({ children }: { children: React.ReactNode }) {
    return (
        <ClerkProvider publishableKey={process.env.NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY}>  
                <Provider store={store}>
                    <NextUIProvider>{children}</NextUIProvider>
                </Provider>
            </ClerkProvider>
    );
}
